import i18n from 'i18next';

import {
    RouteCardMetricValue,
    ValueWithUnit
} from '~/ui/components/RouteCard/RouteCardMetric/types';
import dateUtilsConverters from '~/utils/date-utils-converters';
import filterUtils from '~/utils/filter-utils';
import { CompartmentStat } from '~/api/types';
import constants from '~/utils/constants';
import { RouteTripSummary } from '~/components/MapPageDrawers/SelectedCardsDrawer/types';
import generalUtils from '~/utils/general-utils';
import unitsUtils from '~/utils/unit-converter';

interface SummaryMetrics {
    stats: Stats;
    isMultiCompartmentClient?: boolean;
    maxVolumeCapacity?: number;
    maxWeightCapacity?: number;
}

interface TripSummaryMetrics {
    stats: TripStats;
    vehicleCapacity: VehicleCapacity;
    isMultiCompartmentClient?: boolean;
}

export const getTitleLine = ({
    clientName,
    driverName,
    isPlanned,
    vehicleEid,
    isAppendedRoute = false
}: {
    clientName?: string;
    driverName?: string;
    isPlanned?: boolean;
    vehicleEid?: string;
    isAppendedRoute?: boolean;
}): string => {
    const translationKey = isAppendedRoute ? 'routeNameOnly' : 'routeAndName';

    if (!isPlanned) {
        return filterUtils.getInitials(clientName || '');
    }

    return i18n.t(translationKey, {
        driverName,
        ns: 'translation',
        routeName: vehicleEid
    });
};

const getDurationValuesWithUnits = (routeDuration: number) => {
    const { hours, minutes } =
        dateUtilsConverters.convertMillisecondsToHoursAndMinutesAndSeconds(
            routeDuration
        );
    const ns = 'common';
    const hourUnit = i18n
        .t('time.h', {
            ns,
            time: ''
        })
        .trim();
    const minutesUnit = i18n
        .t('time.min', {
            ns,
            time: ''
        })
        .trim();
    const hourValueAndUnit = hours
        ? {
              value: hours.toString(),
              unit: hourUnit
          }
        : null;
    const minuteValueAndUnit = minutes
        ? {
              value: minutes.toString(),
              unit: minutesUnit
          }
        : null;

    return [hourValueAndUnit, minuteValueAndUnit].filter(
        Boolean
    ) as ValueWithUnit[];
};

export interface VehicleCapacity {
    vehicleMaxWeight: number;
    vehicleMaxVolume: number;
}

export interface Stats {
    distance: number;
    duration: number;
    numTasks: number;
    weightUsed: number;
    volumeCapacityUsed: number;
    compartmentStats: CompartmentStat[];
}

export interface TripStats {
    distance: number;
    duration: number;
    numTasks: number;
    weightUsed: number;
    volumeCapacityUsed: number;
    pickupWeightUsed: number;
    deliveryWeightUsed: number;
    pickupVolumeCapacityUsed: number;
    deliveryVolumeCapacityUsed: number;
    twoPartTaskVolumeCapacityUsed: number;
    twoPartTaskWeightUsed: number;
    compartmentStats: CompartmentStat[];
}

const getVolumeCapacityUsedValueAndUnit = (count: number) => {
    const unit = i18n.t('unit', {
        ns: 'translation',
        count
    });

    return {
        unit,
        value: count.toString()
    };
};

const getStopsValueAndUnit = (count: number) => {
    const unit = i18n.t('stop', {
        ns: 'translation',
        count
    });

    return {
        unit,
        value: count.toString()
    };
};

const getWeightValueAndUnit = (weight: number) => {
    const unit = i18n.t('weight', {
        weight,
        ns: 'common'
    });

    return {
        unit: unit.toLowerCase(),
        value: Math.ceil(weight).toString()
    };
};

const getDistanceValueAndUnit = (totalMeters: number) => {
    const unitKey = unitsUtils.getLocalDistanceUnits({ useAbbreviated: true });
    const unit = i18n.t(unitKey, { distance: '' }).trim();
    const distance = unitsUtils.getLocalDistance(totalMeters);

    return {
        unit,
        value: distance
    };
};

const calculateExceededCapacity = (
    usedCapacity: number,
    maxCapacity: number
) => {
    const diff = generalUtils.roundToMaxDigitsAfterDecimal(
        usedCapacity - maxCapacity,
        2
    );

    return diff > 0 ? diff : 0;
};

const getVolumeMetric = ({
    volumeCapacityUsed,
    maxVolumeCapacity
}: {
    volumeCapacityUsed: number;
    maxVolumeCapacity?: number;
}) => {
    const volumeCapacityUsedValueAndUnit = getVolumeCapacityUsedValueAndUnit(
        generalUtils.roundToMaxDigitsAfterDecimal(volumeCapacityUsed, 4)
    );
    const maxVolume =
        !maxVolumeCapacity && maxVolumeCapacity !== 0
            ? volumeCapacityUsed
            : maxVolumeCapacity;
    const exceededCapacity = calculateExceededCapacity(
        volumeCapacityUsed,
        maxVolume
    );
    const isOverCapacity = Boolean(exceededCapacity);
    const tooltipText = isOverCapacity
        ? i18n.t('overCapacityBy', {
              ns: 'translation',
              count: exceededCapacity
          })
        : undefined;

    return {
        ...(isOverCapacity && {
            isOverCapacity,
            tooltipText
        }),
        metric: constants.routeSummaryMetrics.VOLUME,
        valuesWithUnits: [volumeCapacityUsedValueAndUnit]
    };
};

export const getSummaryMetrics = ({
    stats,
    isMultiCompartmentClient,
    maxVolumeCapacity,
    maxWeightCapacity
}: SummaryMetrics): RouteCardMetricValue[] => {
    const {
        distance,
        duration,
        weightUsed,
        volumeCapacityUsed,
        numTasks: numStops
    } = stats;
    const distanceValueAndUnit = getDistanceValueAndUnit(distance);
    const durationValuesWithUnits = getDurationValuesWithUnits(duration);
    const stopsValueAndUnit = getStopsValueAndUnit(numStops);
    const weightValueAndUnit = getWeightValueAndUnit(weightUsed);

    const metrics = [
        {
            metric: constants.routeSummaryMetrics.STOPS,
            valuesWithUnits: [stopsValueAndUnit]
        },
        {
            metric: constants.routeSummaryMetrics.DISTANCE,
            valuesWithUnits: [distanceValueAndUnit]
        },
        {
            metric: constants.routeSummaryMetrics.DURATION,
            valuesWithUnits: durationValuesWithUnits
        },
        getVolumeMetric({
            volumeCapacityUsed,
            maxVolumeCapacity
        }),
        {
            maxValue: maxWeightCapacity,
            metric: constants.routeSummaryMetrics.WEIGHT,
            valuesWithUnits: [weightValueAndUnit]
        }
    ];

    return metrics.filter((item) => {
        if (!isMultiCompartmentClient) {
            return item;
        }
        return item.metric !== constants.routeSummaryMetrics.VOLUME;
    });
};

export const getTripSummaryMetrics = ({
    stats,
    vehicleCapacity
}: TripSummaryMetrics): RouteCardMetricValue[] => {
    const { vehicleMaxWeight } = vehicleCapacity || {};

    const {
        pickupWeightUsed,
        deliveryWeightUsed,
        pickupVolumeCapacityUsed,
        deliveryVolumeCapacityUsed,
        twoPartTaskWeightUsed,
        twoPartTaskVolumeCapacityUsed,
        numTasks,
        distance,
        duration
    } = stats;
    const distanceValueAndUnit = getDistanceValueAndUnit(distance);
    const durationValuesWithUnits = getDurationValuesWithUnits(duration);
    const stopsValueAndUnit = getStopsValueAndUnit(numTasks);
    const pickupWeightMetric = getWeightValueAndUnit(pickupWeightUsed);
    const deliveryWeightMetric = getWeightValueAndUnit(deliveryWeightUsed);
    const pickupVolumeUsed = getVolumeCapacityUsedValueAndUnit(
        generalUtils.roundToMaxDigitsAfterDecimal(pickupVolumeCapacityUsed, 4)
    );
    const deliveryVolumeUsed = getVolumeCapacityUsedValueAndUnit(
        generalUtils.roundToMaxDigitsAfterDecimal(deliveryVolumeCapacityUsed, 4)
    );
    let twoPartTaskVolume;
    let twoPartTaskWeight;

    if (twoPartTaskWeightUsed) {
        twoPartTaskWeight = getWeightValueAndUnit(twoPartTaskWeightUsed);
    }

    if (twoPartTaskVolumeCapacityUsed) {
        twoPartTaskVolume = getVolumeCapacityUsedValueAndUnit(
            generalUtils.roundToMaxDigitsAfterDecimal(
                twoPartTaskVolumeCapacityUsed,
                4
            )
        );
    }

    return [
        {
            metric: constants.routeSummaryMetrics.STOPS,
            valuesWithUnits: [stopsValueAndUnit]
        },
        {
            metric: constants.routeSummaryMetrics.DISTANCE,
            valuesWithUnits: [distanceValueAndUnit]
        },
        {
            metric: constants.routeSummaryMetrics.DURATION,
            valuesWithUnits: durationValuesWithUnits
        },
        {
            metric: constants.tripSpecificSummaryMetrics.PICKUP_VOLUME,
            valuesWithUnits: [pickupVolumeUsed]
        },
        {
            metric: constants.tripSpecificSummaryMetrics.DELIVERY_VOLUME,
            valuesWithUnits: [deliveryVolumeUsed]
        },
        {
            ...(vehicleMaxWeight && { maxValue: vehicleMaxWeight }),
            metric: constants.tripSpecificSummaryMetrics.PICKUP_WEIGHT,
            valuesWithUnits: [pickupWeightMetric]
        },
        {
            ...(vehicleMaxWeight && { maxValue: vehicleMaxWeight }),
            metric: constants.tripSpecificSummaryMetrics.DELIVERY_WEIGHT,
            valuesWithUnits: [deliveryWeightMetric]
        },
        twoPartTaskVolumeCapacityUsed && {
            metric: constants.tripSpecificSummaryMetrics.TWO_PART_TASK_VOLUME,
            valuesWithUnits: [twoPartTaskVolume]
        },
        twoPartTaskWeightUsed && {
            ...(vehicleMaxWeight && { maxValue: vehicleMaxWeight }),
            metric: constants.tripSpecificSummaryMetrics.TWO_PART_TASK_WEIGHT,
            valuesWithUnits: [twoPartTaskWeight]
        }
    ].filter(Boolean) as RouteCardMetricValue[];
};

export const getTripCompartmentStatsByRouteId = (
    trips: RouteTripSummary[],
    routeId: string
): CompartmentStat[] | undefined => {
    const matchingTrip = (trips || []).find((trip) => trip.routeId === routeId);
    return matchingTrip?.stats?.compartmentStats;
};
