import React, { Fragment } from 'react';
import Supercluster from 'supercluster';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';
import { DepotMarker, DriverLocationMarker, RouteLine } from '~/ui';
import { markerMaker, markerUtils } from '~/utils/map';
import { colorUtils } from '~/utils/color-utils';
import { idUtils } from '~/utils/id-utils';
import { LiveStop, Task } from '~/data-classes';
import { sortBy, uniqWith } from 'lodash';
import { ApiLiveDriver, ApiTask, GoogleMapsInstance } from '~/api/types';
import { MapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { EmittedEventHandler } from '~/components/MapPage/PlanMap/types';
import { HookOnDemandDispatchMarkerEventHandler } from '~/hooks';
import { groupScheduleByDepots } from '~/components/MapPageDrawers/utils';
import { makeLiveStopMarkers } from '~/components/MapPage/PlanMap/makeLiveStopMarkers';

type LiveRoutesStopComponents = {
    depotMarkers: JSX.Element[];
    stopMarkers: JSX.Element[];
    routeLines: JSX.Element[];
    driverMarker: JSX.Element;
};

type LiveRoutesComponent = {
    liveRoutesStopMarkers: JSX.Element[];
    liveRoutesIsolatedRouteLines: JSX.Element[];
    liveRoutesDepotMarkers: JSX.Element[];
};

interface MakeLiveRoutesStopComponentsProps {
    selectedDriver: ApiLiveDriver;
    mapInstance: GoogleMapsInstance;
    mapRouteMode: MapRouteMode;
    onDemandDispatchMarkerEventHandler: HookOnDemandDispatchMarkerEventHandler;
    emittedEventHandler: EmittedEventHandler;
    liveStopsSuperClusters: Supercluster.AnyProps[];
    getClusters: (
        superCluster: Supercluster.AnyProps
    ) => Supercluster.AnyProps[];
    isClusteringStops: boolean;
    driverPrivacy?: boolean;
}

interface MakeLiveRoutesComponentsProps {
    selectedClientDriverIds: string[];
    dispatchedDrivers: ApiLiveDriver[];
    showDrawerOnDemandDispatch: boolean;
    onDemandDispatchTasks: ApiTask[];
    onDemandDispatchMarkerEventHandler: HookOnDemandDispatchMarkerEventHandler;
    mapInstance: GoogleMapsInstance;
    mapRouteMode: MapRouteMode;
    isOpenSuggestDrawer: boolean;
    emittedEventHandler: EmittedEventHandler;
    liveStopsSuperClusters: Supercluster.AnyProps[];
    getClusters: (
        superCluster: Supercluster.AnyProps
    ) => Supercluster.AnyProps[];
    isClusteringStops: boolean;
    driverPrivacy?: boolean;
}

const makeDepotMarkers = (depotElements: LiveStop[]) => {
    return depotElements?.map(({ taskId, location }) => (
        <DepotMarker key={taskId} lat={location.lat} lng={location.lng} />
    ));
};

function makeLiveRoutesStopComponents({
    selectedDriver,
    mapInstance,
    mapRouteMode,
    onDemandDispatchMarkerEventHandler,
    emittedEventHandler,
    liveStopsSuperClusters,
    getClusters,
    isClusteringStops,
    driverPrivacy = false
}: MakeLiveRoutesStopComponentsProps): LiveRoutesStopComponents {
    const driver = new LiveDriver(selectedDriver);
    const { schedule, clientDriverId } = driver;
    const groupedSchedules = groupScheduleByDepots(schedule);
    const routeStops = sortBy(schedule, 'driverStopNumber');
    const driverColor = colorUtils.getWebColorsForId(clientDriverId);

    const depots = routeStops.filter((stop) => {
        return stop.isDepot;
    });
    const depotsEffect = uniqWith(
        depots,
        markerUtils.isDepotsWithTheSameCoordinates
    );

    const depotMarkers = makeDepotMarkers(depotsEffect);
    const stopMarkers = makeLiveStopMarkers({
        mapRouteMode,
        onDemandDispatchMarkerEventHandler,
        driverColor,
        emittedEventHandler,
        liveStopsSuperClusters,
        getClusters,
        isClusteringStops
    });

    const routeLines = groupedSchedules.map((schedules, index) => {
        const [firstStop] = schedules;

        const liveDriver = new LiveDriver({
            clientId: driver.clientId,
            id: driver.driverId,
            stats: {
                currentStop: 0
            },
            cepLocation: firstStop.location,
            schedule: schedules.map(({ isDepot, location }) => ({
                isDepot,
                location: { location: { ...location } }
            }))
        } as ApiLiveDriver);

        const key = idUtils.getCombinedId(liveDriver.id, index.toString());
        return (
            <RouteLine
                key={key}
                mapInstance={mapInstance}
                driver={liveDriver}
                showHistory
                colorCSS={driverColor}
                mapRouteMode={mapRouteMode}
            />
        );
    });

    const driverMarker = (
        <DriverLocationMarker
            key={driver.id}
            direction={driver.direction}
            color={driverColor.backgroundColor}
            lat={driver.location.lat}
            lng={driver.location.lng}
        />
    );

    return {
        depotMarkers,
        stopMarkers,
        routeLines: isClusteringStops ? [] : routeLines,
        driverMarker:
            isClusteringStops || driverPrivacy ? (
                <Fragment key={driver.id} />
            ) : (
                driverMarker
            )
    };
}

/**
 * Creates on demand dispatch stop markers for all given tasks
 * @param {*} onDemandDispatchTasks tasks to create markers for
 * @param {Function} emittedEventHandler event handler for on demand dispatch stop markers
 * @return {{
 *   liveRoutesStopMarkers: StopMarker[],
 *   liveRoutesIsolatedRouteLines: Array,
 *   liveRoutesDepotMarkers: Array
 * }}
 */
function makeOnDemandDispatchStopMarkers(
    onDemandDispatchTasks: ApiTask[],
    onDemandDispatchMarkerEventHandler: HookOnDemandDispatchMarkerEventHandler
): LiveRoutesComponent {
    const stopMarkers = onDemandDispatchTasks.reduce(
        (markers: JSX.Element[], task: ApiTask) => {
            const unassignedTask = new Task(task);
            if (unassignedTask.isPickup) {
                markers.push(
                    markerMaker.makeOnDemandDispatchStopMarker(
                        unassignedTask.pickupStopData,
                        onDemandDispatchMarkerEventHandler
                    ) as unknown as JSX.Element
                );
            }
            if (unassignedTask.isDelivery) {
                markers.push(
                    markerMaker.makeOnDemandDispatchStopMarker(
                        unassignedTask.deliveryStopData,
                        onDemandDispatchMarkerEventHandler
                    ) as unknown as JSX.Element
                );
            }
            return markers;
        },
        []
    );
    return {
        liveRoutesStopMarkers: stopMarkers,
        liveRoutesIsolatedRouteLines: [],
        liveRoutesDepotMarkers: []
    };
}

export const makeLiveRoutesComponents = ({
    selectedClientDriverIds,
    dispatchedDrivers,
    showDrawerOnDemandDispatch,
    onDemandDispatchTasks,
    onDemandDispatchMarkerEventHandler,
    mapInstance,
    mapRouteMode,
    isOpenSuggestDrawer,
    emittedEventHandler,
    liveStopsSuperClusters,
    getClusters,
    isClusteringStops,
    driverPrivacy = false
}: MakeLiveRoutesComponentsProps): LiveRoutesComponent => {
    if (
        showDrawerOnDemandDispatch &&
        onDemandDispatchTasks.length &&
        !isOpenSuggestDrawer
    ) {
        return makeOnDemandDispatchStopMarkers(
            onDemandDispatchTasks,
            onDemandDispatchMarkerEventHandler
        );
    }

    const filterUniqueMarkers = (
        routesStopMarkers: JSX.Element[],
        driverStopMarkers: JSX.Element[]
    ) => {
        const mergedArray = [...routesStopMarkers, ...driverStopMarkers];
        const uniqueKeys = new Set();
        return mergedArray.filter((item) => {
            if (item === null) return true;
            if (!uniqueKeys.has(item.key)) {
                uniqueKeys.add(item.key);
                return true;
            }
            return false;
        });
    };

    const selectedDriversComponents = dispatchedDrivers
        .filter((driver) => {
            return selectedClientDriverIds.includes(
                idUtils.getCombinedId(driver.clientId, driver.id)
            );
        })
        .map((selectedDriver) => {
            return makeLiveRoutesStopComponents({
                selectedDriver,
                mapInstance,
                mapRouteMode,
                onDemandDispatchMarkerEventHandler,
                emittedEventHandler,
                liveStopsSuperClusters,
                getClusters,
                isClusteringStops,
                driverPrivacy
            });
        });

    const selectedDriversComp = selectedDriversComponents.reduce(
        (
            allComponents: {
                liveRoutesDepotMarkers: JSX.Element[];
                liveRoutesStopMarkers: JSX.Element[];
                liveRoutesIsolatedRouteLines: JSX.Element[];
            },
            currentDriverComponents: ReturnType<
                typeof makeLiveRoutesStopComponents
            >
        ) => {
            const { depotMarkers, stopMarkers, routeLines, driverMarker } =
                currentDriverComponents;
            const selectedDriverStopMarkers = [...stopMarkers, driverMarker];
            const selectedIsolatedRouteLines = routeLines;

            allComponents.liveRoutesDepotMarkers = [...depotMarkers];

            allComponents.liveRoutesStopMarkers = filterUniqueMarkers(
                allComponents.liveRoutesStopMarkers,
                selectedDriverStopMarkers
            );

            allComponents.liveRoutesIsolatedRouteLines = [
                ...allComponents.liveRoutesIsolatedRouteLines,
                ...selectedIsolatedRouteLines
            ];
            return allComponents;
        },
        {
            liveRoutesDepotMarkers: [],
            liveRoutesStopMarkers: [],
            liveRoutesIsolatedRouteLines: []
        }
    );
    if (
        showDrawerOnDemandDispatch &&
        onDemandDispatchTasks.length &&
        isOpenSuggestDrawer
    ) {
        const demandDispatchStopMarkers = makeOnDemandDispatchStopMarkers(
            onDemandDispatchTasks,
            onDemandDispatchMarkerEventHandler
        );
        selectedDriversComp.liveRoutesStopMarkers =
            selectedDriversComp.liveRoutesStopMarkers.concat(
                demandDispatchStopMarkers.liveRoutesStopMarkers
            );
        return selectedDriversComp;
    }

    return selectedDriversComp;
};

export default makeLiveRoutesStopComponents;
