import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { useFeatureFlag } from '../useFeatureFlag';
import { UseIntermodalFeatures } from './types';
import { carKind } from './carKind';

export const useIntermodalFeatures = (): UseIntermodalFeatures => {
    const mainClient = useSelector(selectMainClient);
    const {
        enableLiveDispatch: isEnabledLiveDispatch,
        enableExternalTaskTypes: isEnabledExternalTaskTypes,
        enableGapInRoute: isEnabledGapInRoute,
        enableResetTasks: isEnabledResetTasks,
        enableOperationalStops: isEnabledOperationalStops,
        enableTaskEquipmentField = {}
    } = mainClient?.intermodal || {};

    const {
        externalTaskType: isEnabledFieldExternalTaskType,
        equipmentId: isEnabledFieldEquipmentId,
        carKind: isEnabledFieldCarKind,
        emptyOrderNumber: isEnabledFieldEmptyOrder,
        chassisId: isEnabledFieldChassisId,
        showToDriver: isEnabledFieldShowToDriver
    } = enableTaskEquipmentField;

    const isFeatureEnabledExternalTaskTypes = useFeatureFlag(
        FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES
    );
    const isFeatureEnabledResetTasks = useFeatureFlag(FeatureFlag.RESET_TASKS);
    const isFeatureEnabledOperationalStops = useFeatureFlag(
        FeatureFlag.OPERATIONAL_STOP
    );
    const isFeatureEnabledEditCarKind = useFeatureFlag(
        FeatureFlag.EDIT_CAR_KIND
    );
    const isFeatureEnabledEditEmptyOrder = useFeatureFlag(
        FeatureFlag.EDIT_EMPTY_ORDER_NUMBER
    );
    const isFeatureEnabledEditChassisId = useFeatureFlag(
        FeatureFlag.EDIT_CHASSIS_ID
    );
    const isFeatureEnabledShowToDriver = useFeatureFlag(
        FeatureFlag.SHOW_TO_DRIVER
    );

    const enableLiveDispatch = Boolean(isEnabledLiveDispatch);
    const enableExternalTaskTypes = Boolean(
        isEnabledExternalTaskTypes || isFeatureEnabledExternalTaskTypes
    );
    const enableGapInRoute = Boolean(isEnabledGapInRoute);
    const enableResetTasks = Boolean(
        isEnabledResetTasks || isFeatureEnabledResetTasks
    );
    const enableOperationalStops = Boolean(
        isEnabledOperationalStops || isFeatureEnabledOperationalStops
    );

    const enableEditEquipmentId = Boolean(isEnabledFieldEquipmentId);
    const enableEditExternalTaskType = Boolean(isEnabledFieldExternalTaskType);
    const enableEditCarKind = Boolean(
        isEnabledFieldCarKind || isFeatureEnabledEditCarKind
    );
    const enableEditEmptyOrder = Boolean(
        isEnabledFieldEmptyOrder || isFeatureEnabledEditEmptyOrder
    );
    const enableEditChassisId = Boolean(
        isEnabledFieldChassisId || isFeatureEnabledEditChassisId
    );
    const enableShowToDriver = Boolean(
        isEnabledFieldShowToDriver || isFeatureEnabledShowToDriver
    );

    return {
        carKind,
        enableLiveDispatch,
        enableExternalTaskTypes,
        enableGapInRoute,
        enableResetTasks,
        enableOperationalStops,
        enableEditEquipmentId,
        enableEditExternalTaskType,
        enableEditCarKind,
        enableEditChassisId,
        enableEditEmptyOrder,
        enableShowToDriver
    };
};
